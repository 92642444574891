var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-2/2 mb-base" },
        [
          _c("vx-card", { attrs: { title: "Perfil" } }, [
            _c(
              "div",
              {
                staticClass: "p-6 pb-0",
                attrs: { slot: "no-body" },
                slot: "no-body"
              },
              [
                _c("div", { staticClass: "vx-row mb-6" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: { label: "Nombre" },
                        model: {
                          value: _vm.name,
                          callback: function($$v) {
                            _vm.name = $$v
                          },
                          expression: "name"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-row mb-6" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: { label: "Apellidos" },
                        model: {
                          value: _vm.surname,
                          callback: function($$v) {
                            _vm.surname = $$v
                          },
                          expression: "surname"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-row mb-6" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          type: "email",
                          label: "Email",
                          disabled: "true"
                        },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-row mb-6" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: { label: "Teléfono" },
                        model: {
                          value: _vm.phone,
                          callback: function($$v) {
                            _vm.phone = $$v
                          },
                          expression: "phone"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-button", { staticClass: "mr-3 mb-2" }, [
                        _vm._v("Submit")
                      ]),
                      _c(
                        "vs-button",
                        {
                          staticClass: "mb-2",
                          attrs: { color: "warning", type: "border" },
                          on: {
                            click: function($event) {
                              _vm.name = _vm.surname = _vm.phone = ""
                            }
                          }
                        },
                        [_vm._v("Reset")]
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }