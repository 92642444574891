<template>
  <div>

    <div class="vx-row">

      <!-- Notifications -->
      <div class="vx-col w-full md:w-2/2 mb-base">
        <vx-card title="Perfil">
          <div slot="no-body" class="p-6 pb-0">

            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input class="w-full" label="Nombre" v-model="name" />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input class="w-full" label="Apellidos" v-model="surname" />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input class="w-full" type="email" label="Email" v-model="email" disabled="true" />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input class="w-full" label="Teléfono" v-model="phone" />
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col w-full">
                <vs-button class="mr-3 mb-2">Submit</vs-button>
                <vs-button color="warning" type="border" class="mb-2" @click="name = surname = phone = '';">Reset</vs-button>
              </div>
            </div>

          </div>
        </vx-card>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  data() {
    return {
      name: '',
      surname: '',
      email: '',
      phone: ''
    }
  },
  components: {
  }
}
</script>

<style scoped>
.space-after {
  margin-bottom: 1.5rem;
}
</style>